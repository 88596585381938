<template>
 <v-container class=" home" >
    <v-row class="mx-0">
      <v-col class="text-center ">
          <BannerImage  v-if="(content && content.main_img)" :title="$t('title.home')" :subtitle="`${ (content.home_title) ? content.home_title : $t('title.subhome', {'etab': content.name}) }`" :img="content.main_img"/>
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col >
        <div  class="pa-5" v-if="(content && content.home_content)" v-html="content.home_content"></div>
      </v-col>
    </v-row>
    <v-row dense class="mx-0" v-if="content">
      <v-col
          v-for="card in content.pages"
          :key="card.title"
          :cols="card.flex"
        >
        
        <v-card>
          <router-link :to="$i18nRoute({ name: 'page', params: { page: card.slug }})" class="mosaic__links">
            <v-img
            :src="'/storage/app/media/'+card.image"
            class="white--text align-end"
            style="background-color:var(--color-primary)"
            height="125px"
          >
            
              <v-card-title v-text="card.title" class="has-overlay-gradient"></v-card-title>
            
          </v-img>
          </router-link>
        </v-card>
      </v-col>
      <Feedback />
    </v-row>


  </v-container>

</template>
<style>
.mosaic__links{
  text-decoration: none;
}
.has-overlay-gradient {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.5)25%, transparent 75%);
}
.v-application .primary--text{
  color: var(--color-primary) !important;
}
</style>
<script>
// @ is an alias to /src
import BannerImage from '@/components/BannerImage.vue'
import Feedback from '@/components/Feedback.vue'
import axios from 'axios'


export default {
  name: 'home',
  props: ['etab'],
  components: {
    BannerImage,
    Feedback
  },
  beforeRouteEnter (to, from, next) {
    axios.get('/etab/' + to.params.etab)
          .then((response) => {
            next(vm => vm.setData(null,response.data))
          })
          .catch((err) => {
            next(vm => vm.setData(err, null))
          })
  }, 
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  beforeRouteUpdate (to, from, next) {
    this.content = null
    axios.get('/etab/' + to.params.etab)
      .then((response) => {
        this.setData(null,response.data)
        next()
      })
      .catch((err) => {
        this.setData(err, null)
        next()
      })
  },
  data () {
    return {
      loading: false,
      error: null,
      content: null,
      
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.error = this.content = null
      this.loading = true
      axios.get('/etab/' + this.etab).then(response => {
        this.content =  response.data
        this.loading = false
        document.title = `Alpine Resorts - Room Directory - ` + this.content.name
      })

    },
    setData (err, content) {
      if (err) {
        this.error = err.toString()
      } else {
        this.content = content
        document.title = `Alpine Resorts - Room Directory - ` + this.content.name
      }
    }
  }
}
</script>
