<template>
  <v-col>
    <v-card
        @click.stop="show = true;"
    >
        <v-img
        src=""
        class="white--text align-end"
        style="background-color:var(--color-primary)"
        height="125px"
        >
        
            <v-card-title class="has-overlay-gradient">{{ $t('title.feedbackDialog')}}</v-card-title>
        
        </v-img>
    </v-card>
    <DialogFeedback :show="show" @toggle="toggleDialog"/>
  </v-col>
</template>

<script>
import DialogFeedback from '@/components/DialogFeedback.vue'

export default {
    data(){
        return {
            show : false
        }
    },
    components: {
        DialogFeedback
    },
    methods: {
        toggleDialog(val){
            this.show = val
        }
    }
}
</script>

<style>

</style>