<template>
<v-dialog
      v-model="Feedback"
      fullscreen
      hide-overlay
      :scrollable="!submitted"
      transition="dialog-bottom-transition"
      v-touch="{
        down: () => toggleFeedback()
      }"
    >
      <v-card>
        <v-toolbar
          dark
          color="#4b5c44"
        >
          <v-btn
            icon
            dark
            @click="toggleFeedback"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('title.feedbackDialog')}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="px-2 py-4" v-if="!submitted">
            <div class="pa-4">{{$t('contents.feedback.introduction')}}</div>
            
            <v-divider></v-divider>
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
              >
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                <v-col
                    cols="12"
                    md="4"
                >
                <v-card class="pa-4 my-4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="fullname"
                    rules="required"
                  > 
                    <v-text-field
                    v-model="fullname"
                    :label="$t('contents.feedback.formInputs.fullname')"
                    :error-messages="errors"
                    required
                    ></v-text-field>
                  </validation-provider>
                </v-card>
                <v-card class="pa-4 my-4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    :label="$t('contents.feedback.formInputs.email')"
                    required
                    ></v-text-field>
                  </validation-provider>
                </v-card>
                <v-card class="pa-4 my-4">
                    <v-text-field
                    v-model="appartement"
                    :label="$t('contents.feedback.formInputs.appartNo')"
                    ></v-text-field>
                </v-card>
                <v-card class="pa-4 my-4">
                    <p>{{$t('contents.feedback.formInputs.ratingResidence')}}</p>
                    <v-rating
                      v-model="rating_residence"
                      style="    justify-content: space-evenly;    display: flex;"
                      empty-icon="mdi-star-outline"
                      full-icon="mdi-star"
                      hover
                      dense
                      size="48"
                      length="5"
                      x-large
                    ></v-rating>
                </v-card>
                <v-card class="pa-4 my-4">
                    <p>{{$t('contents.feedback.formInputs.ratingEquipsResidence')}}</p>
                    <v-rating
                      v-model="rating_equips_residence"
                      style="    justify-content: space-evenly;    display: flex;"
                      empty-icon="mdi-star-outline"
                      full-icon="mdi-star"
                      hover
                      dense
                      size="48"
                      length="5"
                      x-large
                    ></v-rating>
                </v-card>
                <v-card class="pa-4 my-4">
                    <p>{{$t('contents.feedback.formInputs.ratingAppart')}}</p>
                    <v-rating
                      v-model="rating_appartement"
                      style="    justify-content: space-evenly;display: flex;"
                      empty-icon="mdi-star-outline"
                      full-icon="mdi-star"
                      hover
                      dense
                      size="48"
                      length="5"
                      x-large
                    ></v-rating>
                </v-card>
                <v-card class="pa-4 my-4">
                    <p>{{$t('contents.feedback.formInputs.RatingEquipsAppart')}}</p>
                    <v-rating
                      v-model="rating_equips_appartement"
                      style="    justify-content: space-evenly;display: flex;"
                      empty-icon="mdi-star-outline"
                      full-icon="mdi-star"
                      hover
                      dense
                      size="48"
                      length="5"
                      x-large
                    ></v-rating>
                </v-card>
                <v-card class="pa-4 my-4">
                    <p>{{$t('contents.feedback.formInputs.RatingComeback')}}</p>
                    <v-rating
                      v-model="rating_comeback"
                      style="    justify-content: space-evenly;display: flex;"
                      empty-icon="mdi-star-outline"
                      full-icon="mdi-star"
                      hover
                      dense
                      size="48"
                      length="5"
                      x-large
                    ></v-rating>
                </v-card>
                    <v-card class="pa-4 my-4">
                      <p>{{$t('contents.feedback.formInputs.feedbackMsg')}}</p>
                      <v-textarea
                        v-model="message"
                        name="input"
                        auto-grow              
                      ></v-textarea>

                    </v-card>
                    <v-btn
                    :disabled="invalid"
                    color="primary"
                    class="mr-4"
                    
                    @click="validate"
                    >
                    {{$t('btns.send')}}
                    </v-btn>

                    <v-btn
                    color="secondary"
                    class="mr-4"
                    @click="reset"
                    >
                    {{$t('btns.reset')}}
                    </v-btn>
                </v-col>
              </v-form>
            </validation-observer>
        </v-card-text>
        <v-alert v-else
          prominent
          text
          type="success"
          transition="dialog-bottom-transition"
          class="alert-column"
        >
          <div class="title" v-html="$t('contents.feedback.thanks')">
          </div>
          <div v-html="$t('contents.feedback.thanksMsg')"></div>
        </v-alert>
      </v-card>
    </v-dialog>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import axios from 'axios'
  export default {
    props: ['id', 'show'],
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data: () => ({
      submitted: false,
      Feedback: false,
      v0: true,
      valid: true,
      fullname: '',
      email: '',
      appartement: '',
      message: '',
      rating_residence: 5,
      rating_appartement: 5,
      rating_comeback: 5,
      rating_equips_appartement: 5,
      rating_equips_residence: 5,
      select: null,
      checkbox: false,
    }),
    watch: {
      // call again the method if the route changes
      'show': function(val) {
        this.Feedback = val
      }
    },
    methods: {
      toggleFeedback(){
        
        this.$emit("toggle",!this.Feedback)
      },
      handleValidationErrorAdvanced () {
        const firstField = Object.keys(this.errors.collect())[0];
        // this assumes you have a conviention of ref and field name here I just add the
        // Input suffix to the field name as you can see in the template.
        this.$refs[`${firstField}`].scrollIntoView();
      },
      validate () {
        var that = this;
        if(this.$refs.observer.validate()){
          var bodyFormData = new FormData();
          bodyFormData.append('fullname', this.fullname)
          bodyFormData.append('email', this.email)
          bodyFormData.append('rating.residence', this.rating_residence)
          bodyFormData.append('rating.equips_residence', this.rating_equips_residence)
          bodyFormData.append('rating.appartement', this.rating_appartement)
          bodyFormData.append('rating.equips_appartement', this.rating_equips_appartement)
          bodyFormData.append('rating.comeback' , this.rating_comeback)
          bodyFormData.append('message', this.message)
          bodyFormData.append('etab', this.$route.params.etab)
          bodyFormData.append('_token', window.csrfToken)
          axios({
          method: 'post',
          url: '/feedback',
          data: bodyFormData,
          headers: {'Content-Type': 'multipart/form-data' }
          })
          .then(function (/*response*/) {
              //handle success
              that.$refs.form.reset()
              that.submitted = !that.submitted
          })
          .catch(function (/*response*/) {
              //handle error
          });
        }else{
          return this.handleValidationErrorAdvanced();
        }
      },
      reset () {
        this.$refs.form.reset()
      },
    }
    
    
  }
</script>
<style>
.v-rating .accent--text {
    color: #b4b4b4 !important;
    caret-color: #b4b4b4 !important;
}
.alert-column > .v-alert__wrapper{
  flex-direction: column;
}
.alert-column > .v-alert__wrapper > .v-icon{
  margin-top: 2rem;
  margin-bottom:2rem;
}
</style>